import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import React, {useEffect, useState} from "react";
import { BASE_URL } from "../../assets/js/_variables";

// Componentes
import Layout from "../../components/Layout";
import Slider from "../../components/Slider";

const diving = () => {
  const [lang, setLang] = useState()
  
  const txt = {
    en: {
      pageTitle: 'DIVING | HB Privileged',
      title: 'DIVING',
      subtitle: 'TOUR OVERVIEW',
      text: "Diving in the warm waters of Banderas Bay if for the ones with an adventurous spirit, but it doesn ́t matter if you are a certified diver or a curious beginner, we have programs for all people. Wheather you want to dive with sea lions next to the spectacular beach of Las Caletas or if you want a professional dive to El Morro, the Vallarta Adventures guides will help you have the best and safest experience. All the instructors have the highest certifications, lots of experience and the best attitude. The gear is new and of the best brands, the staff is the friendliest, the boats are modern and safe and the experts of Vallarta Adventures know the best spots so you can have the greatest adventure! Reserve now!",
      frequency: 'Frequency:',
      hours: 'Hours:',
      duration: 'Duration:',
      btn: 'Book now',
    },
    es: {
      pageTitle: 'BUCEO | HB Privileged',
      title: 'BUCEO',
      subtitle: 'RESUMEN DEL TOUR',
      text: 'El buceo en las cálidas aguas de la Bahía de Banderas es para los de espíritu aventurero, pero no importa si eres buzo certificado o un curioso principiante, tenemos programas para todas las personas. Ya sea que quieras bucear con lobos marinos junto a la espectacular playa de Las Caletas, o si qjuieres una inmersión profesional en El Morro, los guías de Vallarta Adventures te ayudarán a tener la mejor y más segura experiencia. Todos los instructores tienen las más altas certificaciones, mucha experiencia y la mejor actitud. El equipo es nuevo y de las mejores marcas, el staff es el más amigable y las embarcaciones son modernas y seguras y los expertos de Vallarta Adventures conocen los mejores lugares para que tengas la más grande aventura! Reserva ya!',
      frequency: 'Frecuencia:',
      hours: 'Horas:',
      duration: 'Duración:',
      btn: 'Reserva este tour',
    }
  }

  const images = [
    `${BASE_URL}/tours/diving1.jpg`,
    `${BASE_URL}/tours/diving2.jpg`,
    `${BASE_URL}/tours/diving3.jpg`,
  ]

  const getLang = () => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem('lang')
    }
  }

  useEffect(() => {
    if(getLang() === 'es') {
      setLang('es')
    } else {
      setLang('en')
    }
  }, [])

  return (
    <Layout pageTitle={ lang === 'es' ? txt.es.pageTitle : txt.en.pageTitle }>
      <div>
        {/*</div><div className="h-80 overflow-hidden">*/}
        <div className="h-70 overflow-hidden">
          <img src={`${BASE_URL}/banners/diving.jpg`} className="w-full object-cover" alt="" />
        </div>
        <div className="section">
          <h2 className="text-2xl md:text-4xl font-bold mb-2">
            { lang === 'es' ? txt.es.title : txt.en.title }
          </h2>
          <div className="mb-4">
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <h3 className="text-xl md:text-2xl font-semibold mb-4">
                { lang === 'es' ? txt.es.subtitle : txt.en.subtitle }
              </h3>
              <p className="normal-text text-justify mb-4">
                { lang === 'es' ? txt.es.text : txt.en.text }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.frequency : txt.en.frequency }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.hours : txt.en.hours }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.duration : txt.en.duration }
              </p>
            </div>

            <div className="">
              <div className="rounded overflow-hidden">
                <Slider images={images} />
              </div>
              
              <div className="text-center py-8">
                <Link
                  className="text-center w-1/2 mt-16 text-xl font-bold bg-sky-800 text-neutral-50 py-2 px-4 rounded-full" 
                  to={`${BASE_URL}/contacto`}
                >
                  { lang === 'es' ? txt.es.btn : txt.en.btn }
                </Link>
              </div>
            </div>
          </div>
        </div>

      </div>
    </Layout>
  );
}
 
export default diving;